import React, { useEffect } from "react";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import {
  getIsWealthOfSourceValueDisabled,
  getDefaultWealthSourceDefaultValues,
  getWealthSourceValue,
} from "../helper";
import CustomMultiSelect from "../../../widgets/inputs/custom-multi-select";
import {
  incomeSourceEnums,
  wealthSourceEnums,
} from "../../../enums/income-and-wealth";

const WealthSource = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.WEALTH_SOURCE;
  const incomeSourceKey = KycKeys.INCOME_SOURCE_V2;
  const wealthAmountInheritanceKey = KycKeys.WEALTH_AMOUNT_INHERITANCE;
  const wealthDetailsInheritanceKey = KycKeys.WEALTH_DETAILS_INHERITANCE;
  const wealthAmountInvestmentsKey = KycKeys.WEALTH_AMOUNT_INVESTMENTS;
  const wealthDetailsInvestmentsKey = KycKeys.WEALTH_DETAILS_INVESTMENTS;
  const wealthAmountJob = KycKeys.WEALTH_AMOUNT_JOB;
  const wealthAmountSavingKey = KycKeys.WEALTH_AMOUNT_SAVING;
  const wealthDetailsSavingKey = KycKeys.WEALTH_DETAILS_SAVING;
  const wealthAmountSaleKey = KycKeys.WEALTH_AMOUNT_SALE;
  const wealthDetailsSaleKey = KycKeys.WEALTH_DETAILS_SALE;
  const wealthAmountOtherKey = KycKeys.WEALTH_AMOUNT_OTHER;
  const wealthDetailsOtherKey = KycKeys.WEALTH_DETAILS_OTHER;

  const { wealthSources } = kycFields;
  const {
    [fieldKey]: { value },
    [incomeSourceKey]: { value: incomeSourceValue },
  } = data;

  const hasInvestment = incomeSourceValue.some(
    (item) => item === incomeSourceEnums.INVESTMENT
  );
  const hasPensionSocialSecurity = incomeSourceValue.some(
    (item) => item === incomeSourceEnums.PENSION_SOCIAL_SECURITY
  );

  const wealthSourceValues = value.map((val) => ({
    label: wealthSources[val],
    value: val,
  }));
  const wealthSourceOptions = Object.keys(wealthSources).map((key) => ({
    label: wealthSources[key],
    value: key,
    isDisabled: getIsWealthOfSourceValueDisabled(key, hasInvestment),
  }));

  const validateField = (value) => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
      },
      [wealthAmountInheritanceKey]: {
        ...prev[wealthAmountInheritanceKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.INHERITANCE),
      },
      [wealthDetailsInheritanceKey]: {
        ...prev[wealthDetailsInheritanceKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.INHERITANCE),
      },
      [wealthAmountInvestmentsKey]: {
        ...prev[wealthAmountInheritanceKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.INVESTMENT),
      },
      [wealthDetailsInvestmentsKey]: {
        ...prev[wealthDetailsInvestmentsKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.INVESTMENT),
      },
      [wealthAmountJob]: {
        ...prev[wealthAmountJob],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.JOB_INDEMNITY),
      },
      [wealthAmountSavingKey]: {
        ...prev[wealthAmountSavingKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.SAVING),
      },
      [wealthDetailsSavingKey]: {
        ...prev[wealthDetailsSavingKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.SAVING),
      },
      [wealthAmountSaleKey]: {
        ...prev[wealthAmountSaleKey],
        error: "",
        value: "",
        isVisible: value.some(
          (key) => key === wealthSourceEnums.SALE_OF_PROPERTY
        ),
      },
      [wealthDetailsSaleKey]: {
        ...prev[wealthDetailsSaleKey],
        error: "",
        value: "",
        isVisible: value.some(
          (key) => key === wealthSourceEnums.SALE_OF_PROPERTY
        ),
      },
      [wealthAmountOtherKey]: {
        ...prev[wealthAmountOtherKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.OTHER),
      },
      [wealthDetailsOtherKey]: {
        ...prev[wealthDetailsOtherKey],
        error: "",
        value: "",
        isVisible: value.some((key) => key === wealthSourceEnums.OTHER),
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const handleIncomeSourceChange = (
    hasInvestment,
    hasPensionSocialSecurity
  ) => {
    let changedValue = [...wealthSourceValues];

    if (!hasInvestment) {
      changedValue = changedValue.filter(
        (item) => item.value !== wealthSourceEnums.INVESTMENT
      );
    }

    if (!hasPensionSocialSecurity) {
      changedValue = changedValue.filter(
        (item) => item.value !== wealthSourceEnums.JOB_INDEMNITY
      );
    }

    return changedValue;
  };

  const checkDefaultValue = () => {
    const defaultValue = getDefaultWealthSourceDefaultValues(
      wealthSources,
      hasInvestment,
      hasPensionSocialSecurity
    );

    const filteredValue = handleIncomeSourceChange(
      hasInvestment,
      hasPensionSocialSecurity
    );
    if (defaultValue.length > 0) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          error: "",
          value: [
            ...defaultValue.map((option) => option.value),
            ...getWealthSourceValue(filteredValue, defaultValue),
          ],
        },
      }));
    }
  };

  useEffect(() => {
    checkDefaultValue();
  }, [incomeSourceValue]);

  return (
    <CustomMultiSelect
      name={"wealthSource"}
      label={translator("sourceOfWealthLabel")}
      onChange={handleChange}
      defaultValues={value}
      inputOptions={wealthSourceOptions}
    />
  );
};

WealthSource.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default WealthSource;
